import React from 'react';
import PropTypes from 'prop-types';
import { Box, TextField } from '@material-ui/core';

import { Section } from 'src/components';


const ObservationsSection = ({ onChange, value, className }) => <Section title="Observaciones">
  <Box>
    <TextField
      onChange={onChange}
      type="text"
      name="comments"
      className={className}
      label="Observaciones"
      multiline
      minRows={4}
      placeholder="Escribe las consideraciones que tengan con relación al proyecto"
      variant="outlined"
      value={value}
    />
  </Box>
</Section>;

ObservationsSection.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  className: PropTypes.any,
};


export { ObservationsSection };
