import yup from 'src/scenes/yup';


// TODO: confirmar obligatoriedad de todas las cosas: se requiere una fase por área? se requiere una geometría?
// o puede haber solo fases y localidad? se requiere si quiera que haya por lo menos un componente de área en el proyecto?
// localidad por lo menos sabemos que no es obligatorio

const reqSelectorMsg = 'Campo obligatorio. Si no hay, puedes elegir "No informada"';
// TODO: alguna cosa será obligatorio? cuáles son los límites de vegetationCover?
const homogenousSoilUnitSchema = {
  name: yup.string().nullable(),
  idInProject: yup.string().nullable(),
  useCapacity: yup.array().of(yup.number()).nullable(),
  vegetationCover: yup.number().min(0).max(100).nullable(),
  use: yup.string().nullable(),
  erosion: yup.string().nullable(),
  chemicalCharacteristics: yup.string().nullable(),
  biodiversityCapacity: yup.string().required(reqSelectorMsg),
};

const trialPitSchema = {
  name: yup.string().nullable(),
  idInProject: yup.string().nullable(),
  waterTableDepth: yup.number().nullable(),
  soilDepth: yup.number().nullable(),
  slopeRangeUpperLimit: yup.number().integer().nullable().min(0)
    .when('slopeUnitIsPercent', { is: true,
      then: schema => schema.max(100),
      otherwise: schema => schema.max(180),
    }),
  slopeRangeLowerLimit: yup.number().integer().nullable()
    .when('slopeUnitIsPercent', { is: true,
      then: schema => schema.max(100),
      otherwise: schema => schema.max(180),
    }),
  slopeUnitIsPercent: yup.boolean().nullable(),
  slopeClass: yup.string().required(reqSelectorMsg),
  drainage: yup.string().required(reqSelectorMsg),
};

const soilSchema = yup.object({
  geoJson: yup.object()
    .when('badData', { is: true,
      then: schema => schema.nullable(),
      otherwise: schema => schema.required('Si no puedes dar geometrías, marca la casilla de datos erróneos o no disponibles'),
    }),
  homogenousSoilUnits: yup.array().of(yup.lazy(hsu => !hsu.finished ? yup.object() : yup.object().shape(homogenousSoilUnitSchema))),
  trialPits: yup.array().of(yup.lazy(tp => !tp.finished ? yup.object() : yup.object().shape(trialPitSchema))),
  // TODO: decidir si validamos badData y comentarios? (por ej. que si badData entonces los comentarios no estén vacíos...)
  badData: yup.boolean(),
  comments: yup.string(),
});

// Which path names refer to arrays of objects that we validate individually?
export const arraySchemaIds = [ 'homogenousSoilUnits', 'trialPits' ];


export default soilSchema;