import { get } from 'src/services/api';


const getPhases = async data => (await get('/options/phase', data)).data;
const getTemporalities = async data => (await get('/options/temporality', data)).data;
const getBiodiversityCapacities = async data => (await get('/options/biodiversity-capacity', data)).data;
const getSlopeClasses = async data => (await get('/options/slope-class', data)).data;
const getDrainages = async data => (await get('/options/drainage', data)).data;


export {
  getPhases,
  getTemporalities,
  getBiodiversityCapacities,
  getSlopeClasses,
  getDrainages,
};