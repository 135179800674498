import React, { useState } from 'react';
import { Grid, FormControlLabel, Checkbox, Typography, Box, TextField,
  List, ListItem, ListItemText, Divider } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { Map, Section } from 'src/components';


const useStyles = makeStyles(theme => ({
  locality: {
    width: '98%',
    marginTop: theme.spacing(2),
  },
  succes: {
    color: 'green',
  },
  errorList: {
    marginLeft: 20,
  },
}));

const PaWContents = ({ phaseOptions, temporalityOptions, pawContent, updatePawContent, deletePawContent, setProblemDescription }) => {
  const { geoJson, errors, empty, problemsDescription } = pawContent;

  const mapName = `paw-map`;
  const updateState = newData => updatePawContent({ newData });
  const deleteState = () => {
    setListFiles([]);
    deletePawContent();
  };

  const [ checkError, setCheckError ] = useState(false);
  const [ listFiles, setListFiles ] = useState([]);

  const classes = useStyles();

  const handleGeoJson = ({ geoJson, kmlFileName }) => {

    let listErrors = [];
    let validStructure = true;

    if (listFiles.includes(kmlFileName)) {
      listErrors = [ { name: '', error: `Ya ha sido cargado un archivo con el nombre ${kmlFileName}.` } ];
      updateState({
        geoJson: {
          type: 'FeatureCollection',
          features: [],
        },
        errors: listErrors,
      });
      return;
    }

    if (!geoJson.features?.length) {
      updateState({ geoJson: null });
    }
    const uniqueInList = (name, list) => list.filter(item => item === name).length === 1;

    const checkedData = [];
    let invalidFeatureName = '';

    for (let i = 0; i < geoJson.features.length; i++) {
      const feature = geoJson.features[i];
      const { properties } = feature;


      let nameToCheck;
      let phasesToCheck;
      let temporalityToCheck;

      Object.keys(properties).forEach(key => {
        const normalizedKey = key.toLowerCase().trim();
        if (normalizedKey === 'nombre' || normalizedKey === 'name') {
          nameToCheck = properties[key]?.toString()?.trim();
        } else if (normalizedKey === 'fases' || normalizedKey === 'phases') {
          const tempPhases = properties[key].toString().split(',');
          phasesToCheck = tempPhases.map(phase => phase.trim().toLowerCase());
        } else if ([ 'temporalidad', 'tempo', 'temporality' ].includes(normalizedKey)) {
          temporalityToCheck = properties[key]?.toLowerCase()?.trim();
        }
      });
      if (!nameToCheck || !phasesToCheck || !temporalityToCheck) {
        validStructure = false;
        invalidFeatureName = nameToCheck;
        break;
      }
      const listPhases = [];
      phasesToCheck.forEach(phase => {
        if (!phaseOptions.includes(phase)) {
          listErrors.push({ name: nameToCheck, error: 'Valor fase inválido', value: phase });
        }
        if (!uniqueInList(phase, phasesToCheck) && !listPhases.includes(phase)) {
          listPhases.push(phase);
          listErrors.push({ name: nameToCheck, error: 'Valor fase repetido', value: phase });
        }
      });
      if (!temporalityOptions.includes(temporalityToCheck)) {
        listErrors.push({ name: nameToCheck, error: 'Valor temporalidad inválido', value: temporalityToCheck });
      }
      checkedData.push({
        ...feature,
        properties: {
          name: nameToCheck,
          phases: phasesToCheck.join(','),
          temporality: temporalityToCheck,
        },
      });
    }
    if (!validStructure) {
      listErrors = [ { name: '', error: invalidFeatureName ?
        `Error en estructura de los datos. El elemento ${invalidFeatureName} no posee campo "fases" y/o "valor"` :
        `Error en estructura de los datos. Alguno de los elementos no posee al menos uno de los campos: "nombre", "fases", "temporalidad"`,
      } ];
    }
    if (listErrors.length === 0) {
      setListFiles(ps => ([ ...ps, kmlFileName ]));
    }
    updateState({
      geoJson: {
        type: 'FeatureCollection',
        features: listErrors.length === 0 ? checkedData : [],
      },
      // eslint-disable-next-line
      errors: listErrors,
      empty: false,
    });
    return;
  };

  return <Section title="Información cargada">
    <Grid container direction="row" spacing={2}>
      <Grid item xs={6}>
        { empty ?
          <Grid container direction="row" spacing={4}>
            <Grid item xs={12} sm container direction="column" spacing={2}>
              <Grid item>
                <Typography variant="h6" component="span" color="textSecondary">
                  Aún no se ha cargado documento
                </Typography>
              </Grid>
            </Grid>
          </Grid> :
          <>
            { listFiles.length > 0 &&
              <>
                <Typography variant="h6" component="span" color="textSecondary">
                  Documentos:
                </Typography>
                <List>
                  {listFiles.map((filename, i) => (
                    <Box key={`filename-${i}`}>
                      <ListItem alignItems="flex-start">
                        - {filename}
                      </ListItem>
                    </Box>
                  ))}
                </List>
              </>
            }
            { errors.length > 0 ?
              <>
                <Typography variant="h6" component="span">
                  Errores detectados al cargar documento:
                </Typography>
                <List>
                  {errors.map((error, i) => (
                    <Box key={i}>
                      <ListItem alignItems="flex-start">
                        <Grid container direction="column">
                          {error.name ? (
                            <Grid item>
                              <Alert severity="error">{`Error encontrado en elemento: ${error.name}`}</Alert>
                            </Grid>
                          ) : (
                            <Grid item>
                              <Alert severity="error">{`Error al cargar archivo`}</Alert>
                            </Grid>
                          ) }
                          <Grid item>
                            <ListItemText className={classes.errorList}
                              primary={error.error || ''}
                              secondary={error.value || ''}
                            />
                          </Grid>
                        </Grid>
                      </ListItem>
                      {i < errors.length - 1 && <Divider component="li" />}
                    </Box>
                  ))}
                </List>
              </> :
              <Grid container direction="row" spacing={4}>
                <Grid item xs={12} sm container direction="column" spacing={2}>
                  <Grid item>
                    <Typography variant="h6" component="span" className={classes.succes}>
                        ¡Documento cargado con éxito!
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            }
          </>
        }
        { checkError &&
            <Grid item xs>
              <Box my={3}>
                <Box>
                  <TextField
                    className={classes.locality}
                    label="Descripción problemas en kml"
                    minRows={8}
                    maxRows={14}
                    multiline
                    name="descripcion-problemas"
                    onChange={e => setProblemDescription(e.target.value)}
                    placeholder="Describe los problemas que hay con las geometrías"
                    type="text"
                    value={problemsDescription}
                    variant="outlined"
                  />
                </Box>
              </Box>
            </Grid>
        }
      </Grid>
      <Grid item xs={6}>
        <Map nameId={mapName} geoJson={geoJson} handleGeoJson={handleGeoJson} importKml={true} deleteGeoJson={deleteState} />
        <FormControlLabel
          control={
            <Checkbox color="primary" checked={checkError}
              onChange={event => setCheckError(event.target.checked)}
            />
          }
          labelPlacement="start"
          label="¿Datos erróneos o no disponibles?"
        />
      </Grid>
    </Grid>
  </Section>;
};

PaWContents.propTypes = {
  phaseOptions: PropTypes.array,
  temporalityOptions: PropTypes.array,
  pawContent: PropTypes.object,
  updatePawContent: PropTypes.func,
  deletePawContent: PropTypes.func,
  setProblemDescription: PropTypes.func,
};


export { PaWContents };