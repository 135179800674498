import { put, get } from 'src/services/api';


const saveForm = async data => await put('/parts-and-works', data);
const getCurrentStatus = async projectId => (await get(`/parts-and-works/current-status/${projectId}`)).data;


export {
  saveForm,
  getCurrentStatus,
};